import emailjs from "@emailjs/browser";
import {
    Card,
    CardContent,
    Grid,
    Typography,
    TextField,
    Button,
    Snackbar,
    Alert,
    CircularProgress,
    Fade,
} from "@mui/material";
import React, { useRef } from "react";

const ContactForm = ({}) => {
    const form = useRef();

    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const submitHandler = (e) => {
        setLoading(true);
        emailjs
            .sendForm(
                "service_vtb3byv",
                "template_0vm9yn9",
                form.current,
                "yDOZHSAWIwjqKQqER"
            )
            .then(
                (result) => {
                    setOpenSuccess(true);
                    setLoading(false);
                },
                (error) => {
                    setOpenError(true);
                    setLoading(false);
                }
            );
        e.preventDefault();
    };

    const onCloseHandler = (e) => {
        setOpenSuccess(false);
        setOpenError(false);
    };

    return (
        <div id="contact-form-container">
            <Grid>
                <Card
                    style={{
                        maxWidth: 450,
                        padding: "20px 5px",
                        margin: "0 auto",
                    }}
                >
                    <CardContent>
                        <Typography gutterBottom variant="h5">
                            Interested in my work? Let's Connect!
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            gutterBottom
                        >
                            Fill up the form and I will get back to you as soon
                            as I can.
                        </Typography>
                        <form ref={form} onSubmit={submitHandler}>
                            <Grid container spacing={1}>
                                <Grid xs={12} sm={6} item>
                                    <TextField
                                        name="firstName"
                                        placeholder="Enter first name"
                                        label="First Name"
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} item>
                                    <TextField
                                        name="lastName"
                                        placeholder="Enter last name"
                                        label="Last Name"
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="email"
                                        type="email"
                                        placeholder="Enter email"
                                        label="Email"
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="phone"
                                        type="number"
                                        placeholder="Enter phone number"
                                        label="Phone (optional)"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="message"
                                        label="Message"
                                        multiline
                                        rows={4}
                                        placeholder="Let's work together - write your request here!"
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        className="btn"
                                        type="submit"
                                        fullWidth
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                    <Fade in={loading} unmountOnExit>
                        <center>
                            <CircularProgress />
                        </center>
                    </Fade>
                </Card>
            </Grid>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={openSuccess}
                autoHideDuration={3000}
                onClose={onCloseHandler}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    Email sent.
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={openError}
                autoHideDuration={3000}
                onClose={onCloseHandler}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    Error sending email - Please try again later.
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ContactForm;
