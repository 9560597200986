import React from "react";
import { useEffect } from "react";
import { Fade } from "react-awesome-reveal";

import wedding from "../images/title_slideshow/wedding.JPG";
import portrait from "../images/title_slideshow/portrait.jpg";
import car from "../images/title_slideshow/car.jpg";

const delay = 6000;
const images = [wedding, portrait, car];

const TitleSlideshow = () => {
    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }
    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === images.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [index]);
    return (
        <div id="title-slideshow-container">
            <Fade triggerOnce={true}>
                <div id="title-slideshow title-slideshow-height">
                    <div
                        className="title-slideshow-slider"
                        style={{
                            transform: `translate3d(${-index * 100}%, 0, 0)`,
                        }}
                    >
                        {images.map((src, index) => (
                            <div
                                className="title-slideshow-slide title-slideshow-height"
                                key={index}
                            >
                                <img
                                    className="title-slideshow-height"
                                    src={src}
                                ></img>
                            </div>
                        ))}
                    </div>
                </div>
            </Fade>
            <Fade triggerOnce={true}>
                <h1 className="sub-title">Photographer & car enthusiast.</h1>
                <p id="title-slideshow-text">
                    My name is Timothy Andre, I am a photographer who also
                    travels for a living as a flight attendant. I specialize in
                    wedding, portrait and car photography. Currently based out
                    of Montreal with 8 years of experience in photography, my
                    services expand beyond the city. My experience and skill set
                    have enabled me to capture images that will suit your
                    various needs. I believe everyone has a story to tell - my
                    goal and passion is to capture those stories through my
                    photography and bring your vision to life.
                    <br></br>
                </p>
            </Fade>
        </div>
    );
};

export default TitleSlideshow;
