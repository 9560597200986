import Portfolio from "../pages/Portfolio";
import * as Constants from "../misc/Constants";
import p1 from "../images/image_gallery/cars/1.jpg";
import p2 from "../images/image_gallery/cars/2.jpg";
import p3 from "../images/image_gallery/cars/3.jpg";
import p5 from "../images/image_gallery/cars/5.jpg";
import p6 from "../images/image_gallery/cars/6.jpg";
import p7 from "../images/image_gallery/cars/7.jpg";
import p9 from "../images/image_gallery/cars/9.jpg";
import p10 from "../images/image_gallery/cars/10.jpg";
import p11 from "../images/image_gallery/cars/11.jpg";
import p12 from "../images/image_gallery/cars/12.jpg";
import p13 from "../images/image_gallery/cars/13.jpg";
import p14 from "../images/image_gallery/cars/14.jpg";
import p15 from "../images/image_gallery/cars/15.jpg";
import p16 from "../images/image_gallery/cars/16.jpg";
import p17 from "../images/image_gallery/cars/17.jpg";
import p18 from "../images/image_gallery/cars/18.jpg";
import p19 from "../images/image_gallery/cars/19.jpg";
import p20 from "../images/image_gallery/cars/20.jpg";
import p24 from "../images/image_gallery/cars/24.jpg";
import p25 from "../images/image_gallery/cars/25.jpg";
import p26 from "../images/image_gallery/cars/26.jpg";
import p27 from "../images/image_gallery/cars/27.jpg";
import p28 from "../images/image_gallery/cars/28.jpg";
import p29 from "../images/image_gallery/cars/29.jpg";
import p30 from "../images/image_gallery/cars/30.jpg";
import p31 from "../images/image_gallery/cars/31.jpg";
import p32 from "../images/image_gallery/cars/32.jpg";
const Cars = () => {
    const images = [
        p1,
        p2,
        p3,
        p5,
        p6,
        p7,
        p9,
        p10,
        p11,
        p12,
        p13,
        p14,
        p15,
        p16,
        p17,
        p18,
        p19,
        p20,
        p24,
        p25,
        p26,
        p27,
        p28,
        p29,
        p30,
        p31,
        p32,
    ];
    return (
        <Portfolio
            style={Constants.CARS_STYLE}
            description="My passion for cars is mirrored in every photo I take - I put my all into producing pictures to highlight every detail of your car. I provide many photography services, including car shows, car meets and private shoots. I have a variety of vehicles in my portfolio, from luxury sports cars to vintage vehicles. I am dedicated to giving you premium pictures that you will want to display. "
            index={Constants.CARS}
            images={images}
        />
    );
};

export default Cars;
