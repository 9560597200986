import { Button } from "@mui/material";
import { Fade } from "react-awesome-reveal";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ImageDescription = ({
    index,
    img,
    description,
    title,
    btn_href,
    btn_title,
    btn_target,
}) => {
    const text = (float) => {
        return (
            <div className={`i-d-text`}>
                <h1 className="text-sub-title i-d-sub-title">{title}</h1>
                <p>
                    {description}
                    <br />
                    <Button
                        className="btn"
                        href={btn_href}
                        target={btn_target ? "_blank" : ""}
                    >
                        {btn_title}
                    </Button>
                </p>
            </div>
        );
    };

    const image = (float) => {
        return (
            <LazyLoadImage effect="blur" className={`i-d-image`} src={img} />
        );
    };
    return (
        <Fade triggerOnce={true}>
            <div className="i-d-container">
                <div className="i-d-alternate">
                    <div
                        className={`i-d-sub-container i-d-sub-container-${
                            index % 2 == 1 ? "left" : "right"
                        }`}
                    >
                        {image()}
                    </div>
                    <div
                        className={`i-d-sub-container i-d-sub-container-${
                            index % 2 == 0 ? "left" : "right"
                        }`}
                    >
                        {text()}
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default ImageDescription;
