import { Button } from "@mui/material";
import * as Constants from "../misc/Constants";
const DescHeader = ({ title, description }) => {
    return (
        <div className="desc-header-container">
            <h1 className="text-sub-title desc-header-title">{title}</h1>
            <p className="desc-header-text">
                {description}
                <br />
                <Button className="btn" href={Constants.CONTACT_NAV}>
                    {Constants.CONTACT_STYLE}
                </Button>
            </p>
        </div>
    );
};

export default DescHeader;
