import DescHeader from "../components/DescHeader";

import Footer from "../components/Footer";
import ImageGallery from "../components/ImageGallery";

const Portfolio = ({ style, index, description, images }) => {
    return (
        <>
            <div id="portfolio-container">
                <DescHeader title={style} description={description} />
                <ImageGallery images={images} />
            </div>
            <Footer />
        </>
    );
};

export default Portfolio;
