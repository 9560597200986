export const HOME = 0;
export const WEDDINGS = 1;
export const PORTRAITS = 2;
export const CARS = 3;
export const CONTACTS = 4;

export const MAIN_TITLE = "Timothy Andre";

export const HOME_STYLE = "Home";
export const ABOUT_STYLE = "About";
export const WEDDINGS_STYLE = "Weddings";
export const PORTRAITS_STYLE = "Portraits";
export const CARS_STYLE = "Cars";
export const CONTACT_STYLE = "Contact";

export const HOME_NAV = "/";
export const WEDDINGS_NAV = "/weddings";
export const PORTRAITS_NAV = "/portraits";
export const CARS_NAV = "/cars";
export const CONTACT_NAV = "/contact";
