import { Grid } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import { IconButton } from "@mui/material";
import * as Constants from "../misc/Constants";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

const Footer = () => {
    const onClick = () => {
        window.location.refresh(false);
    };
    return (
        <div id="footer-container">
            <Fade cascade triggerOnce={true}>
                <div className="footer-top-container">
                    <div className="footer-top-left-container">
                        <p className="footer-title">
                            Montreal Based Photographer
                        </p>
                        <p className="footer-text">
                            Timothy Andre
                            <IconButton
                                href="https://www.instagram.com/createdbytim/"
                                target="_blank"
                            >
                                <InstagramIcon sx={{ color: "white" }} />
                            </IconButton>
                        </p>
                    </div>
                    <div className="footer-top-right-container">
                        <div className="footer-top-inner">
                            <p className="footer-sub-title">Services </p>
                            <p className="footer-text footer-links">
                                <Link
                                    onClick={onClick}
                                    to={Constants.WEDDINGS_NAV}
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                    }}
                                >
                                    {Constants.WEDDINGS_STYLE}
                                </Link>
                            </p>
                            <p className="footer-text footer-links">
                                <Link
                                    onClick={onClick}
                                    to={Constants.PORTRAITS_NAV}
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                    }}
                                >
                                    {Constants.PORTRAITS_STYLE}
                                </Link>
                            </p>
                            <p className="footer-text footer-links">
                                <Link
                                    onClick={onClick}
                                    to={Constants.CARS_NAV}
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                    }}
                                >
                                    {Constants.CARS_STYLE}
                                </Link>
                            </p>
                        </div>
                        <div className="footer-top-inner">
                            <p className="footer-sub-title">Info </p>
                            <p className="footer-text footer-links">
                                <Link
                                    onClick={onClick}
                                    to={Constants.CONTACT_NAV}
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                    }}
                                >
                                    {Constants.CONTACT_STYLE}
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom-container">
                    <p id="footer-copyright-notice">
                        © 2023 | All rights reserved.
                    </p>
                </div>
            </Fade>
        </div>
    );
};

export default Footer;
