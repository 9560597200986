import Footer from "../components/Footer";
import RecognizedBrands from "../components/RecognizedBrands";

import * as Constants from "../misc/Constants";
import ImageDescription from "../components/ImageDescription";

import About from "../components/About";
import TitleSlideshow from "../components/TitleSlideshow";

import wedding from "../images/image_description/wedding-min.jpg";
import portrait from "../images/image_description/portrait-min.jpg";
import car from "../images/image_description/car-min.jpg";

const Home = () => {
    return (
        <>
            <div id="home-container">
                <TitleSlideshow />
                <RecognizedBrands />

                <ImageDescription
                    index={0}
                    img={wedding}
                    description="I work hard to convey the excitement, laughter, and love that are present on your wedding day in each and every photograph I take. My style of photography is a blend of classic and modern while emphasizing on candid moments of your love and celebration. I am dedicated to giving you gorgeous pictures of your most special day that you will treasure forever."
                    title={"Wedding photography"}
                    btn_href={Constants.WEDDINGS_NAV}
                    btn_title="Gallery"
                    btn_target={false}
                />
                <ImageDescription
                    index={1}
                    img={portrait}
                    description="I am a firm believer that everyone has a story to tell. Whether you're looking for family portraits, professional headshots, or creative portraits, my photography will showcase your unique personality and style. I offer a safe space to collaborate with my clients to bring out the best results. "
                    title={"Portrait photography"}
                    btn_href={Constants.PORTRAITS_NAV}
                    btn_title="Gallery"
                    btn_target={false}
                />
                <ImageDescription
                    index={0}
                    img={car}
                    description="My passion for cars is mirrored in every photo I take - I put my all into producing pictures to highlight every detail of your car. I provide many photography services, including car shows, car meets and private shoots. I have a variety of vehicles in my portfolio, from luxury sports cars to vintage vehicles. I am dedicated to giving you premium pictures that you will want to display. "
                    title={"Car photography"}
                    btn_href={Constants.CARS_NAV}
                    btn_title="Gallery"
                    btn_target={false}
                />
            </div>
            <Footer />
        </>
    );
};

export default Home;
