import Portfolio from "../pages/Portfolio";
import * as Constants from "../misc/Constants";
import React from "react";
import p1 from "../images/image_gallery/weddings/1.jpg";
import p2 from "../images/image_gallery/weddings/2.jpg";
import p3 from "../images/image_gallery/weddings/3.jpg";
import p5 from "../images/image_gallery/weddings/5.jpg";
import p6 from "../images/image_gallery/weddings/6.jpg";
import p8 from "../images/image_gallery/weddings/8.jpg";
import p9 from "../images/image_gallery/weddings/9.jpg";
import p10 from "../images/image_gallery/weddings/10.jpg";
import p11 from "../images/image_gallery/weddings/11.jpg";
import p12 from "../images/image_gallery/weddings/12.jpg";
import p13 from "../images/image_gallery/weddings/13.jpg";
import p14 from "../images/image_gallery/weddings/14.jpg";
import p15 from "../images/image_gallery/weddings/15.jpg";
import p16 from "../images/image_gallery/weddings/16.jpg";
import p17 from "../images/image_gallery/weddings/17.jpg";
import p18 from "../images/image_gallery/weddings/18.jpg";
import p19 from "../images/image_gallery/weddings/19.jpg";
import p20 from "../images/image_gallery/weddings/20.jpg";
import p22 from "../images/image_gallery/weddings/22.jpg";
import p23 from "../images/image_gallery/weddings/23.jpg";
import p24 from "../images/image_gallery/weddings/24.jpg";
import p25 from "../images/image_gallery/weddings/25.jpg";
import p26 from "../images/image_gallery/weddings/26.jpg";
import p27 from "../images/image_gallery/weddings/27.jpg";

const Weddings = () => {
    const images = [
        p1,
        p2,
        p3,
        p5,
        p6,
        p8,
        p9,
        p10,
        p11,
        p12,
        p13,
        p14,
        p15,
        p16,
        p17,
        p18,
        p19,
        p20,
        p22,
        p23,
        p24,
        p25,
        p26,
        p27,
    ];
    return (
        <Portfolio
            style={Constants.WEDDINGS_STYLE}
            description="I work hard to convey the excitement, laughter, and love that are present on your wedding day in each and every photograph I take. My style of photography is a blend of classic and modern while emphasizing on candid moments of your love and celebration. I am dedicated to giving you gorgeous pictures of your most special day that you will treasure forever."
            index={Constants.WEDDINGS}
            images={images}
        />
    );
};

export default Weddings;
