import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link, Outlet } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import * as Constants from "../misc/Constants";
import logoSrc from "../images/global/logo.jpg";
const onClick = () => {
    window.location.refresh(false);
};

const drawerWidth = 400;
const navBar = (
    <nav id="nav-bar-container">
        <ul className="nav-ul">
            <li className="nav-li">
                <Link onClick={onClick} to={Constants.HOME_NAV}>
                    {Constants.HOME_STYLE}
                </Link>
            </li>
            <li className="nav-li">
                <Link onClick={onClick} to={Constants.WEDDINGS_NAV}>
                    {Constants.WEDDINGS_STYLE}
                </Link>
            </li>
            <li className="nav-li">
                <Link onClick={onClick} to={Constants.PORTRAITS_NAV}>
                    {Constants.PORTRAITS_STYLE}
                </Link>
            </li>
            <li className="nav-li">
                <Link onClick={onClick} to={Constants.CARS_NAV}>
                    {Constants.CARS_STYLE}
                </Link>
            </li>
            <li className="nav-li">
                <Link onClick={onClick} to={Constants.CONTACT_NAV}>
                    {Constants.CONTACT_STYLE}
                </Link>
            </li>
            <li className="nav-li-button">
                <IconButton
                    href="https://www.instagram.com/createdbytim/"
                    target="_blank"
                >
                    <InstagramIcon sx={{ color: "black" }} />
                </IconButton>
            </li>
        </ul>
    </nav>
);

const logo = (
    <Link onClick={onClick} to={Constants.HOME_NAV}>
        <img className="img-logo" src={logoSrc}></img>
    </Link>
);
function NavigationBar2(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            {navBar}
            <Divider />
        </Box>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <div>
            <AppBar color="transparent" position="static" component="nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {
                        <Typography
                            component="div"
                            sx={{
                                flexGrow: 1,
                                display: { xs: "none", md: "block" },
                            }}
                        >
                            {logo}
                        </Typography>
                    }
                    <Box sx={{ display: { xs: "none", md: "block" } }}>
                        {navBar}
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", md: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            <Outlet />
        </div>
    );
}

export default NavigationBar2;
