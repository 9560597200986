import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

const Contact = () => {
    return (
        <>
            <div id="contact-container">
                <ContactForm />
            </div>
            <Footer />
        </>
    );
};

export default Contact;
