import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import * as Constants from "./misc/Constants";

import NavigationBar from "./components/NavigationBar";
import Weddings from "./pages/Weddings";
import Portraits from "./pages/Portraits";
import Cars from "./pages/Cars";

function App() {
    return (
        <div className="App">
            <BrowserRouter id="browser-router-dom">
                <Routes>
                    <Route
                        path={Constants.HOME_NAV}
                        element={<NavigationBar />}
                    >
                        <Route index element={<Home />} />
                        <Route
                            path={Constants.WEDDINGS_NAV}
                            element={<Weddings />}
                        />
                        <Route
                            path={Constants.PORTRAITS_NAV}
                            element={<Portraits />}
                        />
                        <Route path={Constants.CARS_NAV} element={<Cars />} />
                        <Route
                            path={Constants.CONTACT_NAV}
                            element={<Contact />}
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
