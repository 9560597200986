import Portfolio from "../pages/Portfolio";
import * as Constants from "../misc/Constants";
import p1 from "../images/image_gallery/portraits/1.jpg";
import p2 from "../images/image_gallery/portraits/2.jpg";
import p3 from "../images/image_gallery/portraits/3.JPG";
import p22 from "../images/image_gallery/portraits/22.jpg";
import p23 from "../images/image_gallery/portraits/23.jpg";
import p24 from "../images/image_gallery/portraits/24.jpg";
import p5 from "../images/image_gallery/portraits/5.jpg";
import p7 from "../images/image_gallery/portraits/7.jpg";
import p8 from "../images/image_gallery/portraits/8.jpg";
import p9 from "../images/image_gallery/portraits/9.jpg";
import p10 from "../images/image_gallery/portraits/10.jpg";
import p11 from "../images/image_gallery/portraits/11.jpg";
import p12 from "../images/image_gallery/portraits/12.jpg";
import p13 from "../images/image_gallery/portraits/13.jpg";
import p14 from "../images/image_gallery/portraits/14.jpg";
import p15 from "../images/image_gallery/portraits/15.jpg";
import p17 from "../images/image_gallery/portraits/17.jpg";
import p18 from "../images/image_gallery/portraits/18.jpg";
import p19 from "../images/image_gallery/portraits/19.JPG";
import p20 from "../images/image_gallery/portraits/20.jpg";
import p21 from "../images/image_gallery/portraits/21.jpg";

const Portraits = () => {
    const images = [
        p2,
        p1,
        p3,
        p22,
        p23,
        p24,
        p8,
        p7,
        p5,
        p19,
        p20,
        p21,
        p9,
        p11,
        p10,
        p12,
        p14,
        p13,
        p17,
        p15,
        p18,
    ];
    return (
        <Portfolio
            style={Constants.PORTRAITS_STYLE}
            description="I am a firm believer that everyone has a story to tell. Whether you're looking for family portraits, professional headshots, or creative portraits, my photography will showcase your unique personality and style. I offer a safe space to collaborate with my clients to bring out the best results. "
            index={Constants.PORTRAITS}
            images={images}
        />
    );
};

export default Portraits;
