import * as React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { trackWindowScroll } from "react-lazy-load-image-component";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ImageGallery = ({ images }) => {
    return (
        <div className="image-gallery-container">
            {images.map((src, index) => (
                <div key={index} className="image-gallery-col">
                    <Zoom>
                        <LazyLoadImage
                            effect="blur"
                            className="image-gallery-image"
                            src={`${src}`}
                            srcSet={`${src}`}
                            width={"100%"}
                            height={600}
                        />
                    </Zoom>
                </div>
            ))}
        </div>
    );
};
export default trackWindowScroll(ImageGallery);
