import React from "react";
import { useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import vw from "../images/recognized/vw.png";
import infiniti from "../images/recognized/infiniti.png";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const images = [vw, infiniti];
const instagram = [
    "https://www.instagram.com/p/Cm_8puCBfcl/",
    "https://www.instagram.com/p/CQEIoWLs9rL/",
];
/*
const images = [
    "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
    "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
];
*/
const delay = 4000;

function RecognizedBrands() {
    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }
    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === images.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [index]);

    return (
        <Fade triggerOnce={true}>
            <div className="slideshow">
                <span className="sub-title">Featured in:</span>
                <div
                    className="slideshowSlider"
                    style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                >
                    {images.map((src, index) => (
                        <div className="slide" key={index}>
                            <a href={instagram[index]} target="_blank">
                                <LazyLoadImage src={src} />
                            </a>
                        </div>
                    ))}
                </div>

                <div className="slideshowDots">
                    {images.map((_, idx) => (
                        <div
                            key={idx}
                            className={`slideshowDot${
                                index === idx ? " active" : ""
                            }`}
                            onClick={() => {
                                setIndex(idx);
                            }}
                        ></div>
                    ))}
                </div>
            </div>
        </Fade>
    );
}

export default RecognizedBrands;
